import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Contact from "../components/contact/contact"
import Services from "../components/services/services"
const ServicesPage = () => (
  <Layout>
    <SEO title="Customized End to End IT Solutions &amp; Development Services" pathname="/services" description="We provide customized end to end IT Solutions &amp; Development Services for your business like Web Development, Mobile/App Development, DevOps, Cloud Solutions etc." />
    <Services/>
  </Layout>
)

export default ServicesPage
