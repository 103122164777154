import React from 'react'
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"
import './services.css'
const getWebImage = graphql`
  {
    fluid: file(relativePath: { eq: "web2.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    fluid2: file(relativePath: { eq: "mob.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    fluid3: file(relativePath: { eq: "devops.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    fluid4: file(relativePath: { eq: "ai.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    fluid5: file(relativePath: { eq: "blockchain.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    fluid6: file(relativePath: { eq: "iot2.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    fluid7: file(relativePath: { eq: "bigdata.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    fluid8: file(relativePath: { eq: "cloud.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    fluid9: file(relativePath: { eq: "i_startup.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`


const Services = () => {
    const data = useStaticQuery(getWebImage);
    const customeEvent = (data) => {
  
      if (typeof window !== "undefined") {
      window.gtag('event', 'click', data);
      
      }
    }
    return (
        <div className="services-first">
  
<section id="web-service">
<div  className="container py-5">
    <div className="row">
        <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-6 service-adjust">
            <h2>Web Development</h2>
            <br/>
            <Img fluid={data.fluid.childImageSharp.fluid} />
            <br/>  
            <h4>
            We provide end to end customized Web Solution, ranging from static websties for small business to ERP solutions for corporates.<br/><br/>
            </h4>
            
        </div>
      
      
        <div className="col-md-6 service-adjust my-auto">
            <div className="row">
            
  <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-4 sbtn btn-1"><h3>Static Websites </h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-4 sbtn btn-2"><h3>Dynamic Websites</h3></div>  
  <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-4 sbtn btn-3"><h3>Web Applications</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-4 sbtn btn-4"><h3>CMS Applications</h3></div>  
  <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-4 sbtn btn-5"><h3>ERP Solutions</h3></div> 
  <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-4 sbtn btn-6"><h3>CRM Solutions</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-4 sbtn btn-7"><h3>E-Commerce Solutions</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-4 sbtn btn-8"><h3>Support & Maintenance</h3></div>
  
  {/* <Link id="mobile-service-scroll" className="col-md-8 text-center" to="/contact"><br/><h5>Explore technologies</h5></Link> */}
  {/* <Link id="mobile-service-scroll" className="col-md-8 btn-grad" to='/contact'>Let's Discuss</Link> */}
  </div>
  
   
  
   

            </div>
            
        </div>
        <div id="mobile-service-scroll" className="row py-3">
     <div id="service-action" className="col-md-6 text-center">
   <Link onClick={()=>customeEvent({
        'event_category': 'Services',
        'event_label': 'Web'
      })}  className="btn-grad" to='/contact'>Let's Discuss</Link>
   </div>
   </div>
    </div>
        


</section>

<section id="service_wrap">
<div  className="container py-5">
    <div className="row">
        <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-6">
            <h2>Mobile App Development</h2>
            <br/>
            <Img fluid={data.fluid2.childImageSharp.fluid} />
            <br/>  
            <h4>
            We provide customize Mobile App Development Solution, ranging from Native apps to cross platform apps.<br/><br/>
            </h4>
        </div>
      
      
        <div className="col-md-6 my-auto">
            <div className="row">
  
  <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-4 sbtn btn-1"><h3>Android </h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-4 sbtn btn-2"><h3>IOS </h3></div>  
  <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-4 sbtn btn-3"><br/><h3>Flutter </h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-4 sbtn btn-4"><h3>React Native </h3></div>  
  <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-4 sbtn btn-5"><br/><h3>Ionic </h3></div> 
  <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-4 sbtn btn-6"><h3>Cross Platform </h3></div>
  <br/>
  
  </div>
   
  
  

            </div>
            
        </div>
        <div id="cloud-service-scroll" className="row py-3">
     <div id="service-action" className="col-md-6 text-center">
   <Link onClick={()=>customeEvent({
        'event_category': 'Services',
        'event_label': 'Mobile'
      })}  className="btn-grad" to='/contact'>Let's Discuss</Link>
   </div>
   </div>
    </div>
        


</section>
   
   
<section id="service_wrap">
<div  className="container py-5">
    <div className="row">
        <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-6">
            <h2>Cloud Solutions</h2>
            <br/>
            <Img fluid={data.fluid8.childImageSharp.fluid} />
            
        </div>

        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6">
        <br/>  
            <h4>
            We provide customize Mobile App Development Solution, ranging from Native apps to cross platform apps.<br/><br/>
            Our cloud solutions enable organizations to reduce IT resource requirements and improve productivity, in addition to lowering costs and reducing the time-to-market.<br/><br/>

We assist customers with our on-demand delivery of computing services, tools and applications such as servers, storage, databases, networking, software, apps, among others.<br/><br/>

By enabling organizations for cloud adoption, we help them become more agile and responsive to the changing market landscape, thus supporting them in the right decision-making and maximizing their Return on Investment (ROI).
            </h4>
        </div>
       
   </div>
      
      <div className="row">
        <div className="col-md-12 my-auto">
            <div className="row">
  
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-1"><h3>Private Cloud</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-2"><h3>Public Cloud</h3></div>  
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-3"><h3>Hybrid Cloud</h3></div></div>
  <div className="row">
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-4"><h3>IaaS</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-5"><h3>SaaS</h3></div> 
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-6"><h3>PaaS</h3></div>
</div>
<div className="row">
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-7"><h3>Cloud management services</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-8"><h3>Application Development Services</h3></div>
</div>
  <br/>
  
  
   
  
  

            </div>
            
        </div>
        <div id="devops-service-scroll" className="row py-3">
     <div id="service-action" className="col-md-6 text-center">
   <Link onClick={()=>customeEvent({
        'event_category': 'Services',
        'event_label': 'Cloud'
      })} className="btn-grad" to='/contact'>Let's Discuss</Link>
   </div>
   </div>
    </div>
        


</section>
     
<section id="service_wrap">
<div  className="container py-5">
    <div className="row">
        <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-6">
            <h2>DevOps Solutions</h2>
            <br/>
            <Img fluid={data.fluid3.childImageSharp.fluid} />
            
        </div>

        <div className="col-md-6">
        <br/>  
        <h3 data-aos="fade-up" data-aos-duration="1000" >We provide DevOps as a service to streamline IT processes and help businesses in following ways</h3>
        <br/>
            <h4>
            
            <ul className="devops-info-ul">
              <li data-aos="fade-up" data-aos-duration="1000">Faster product development lifecycle</li>
              <li data-aos="fade-up" data-aos-duration="1000">Efficient, agile, and cloud-based infrastructure</li>
              <li data-aos="fade-up" data-aos-duration="1000">Automation of end-to-end delivery & deployment</li>
              <li data-aos="fade-up" data-aos-duration="1000">Continuous testing, releasing and maintaining</li>
              <li data-aos="fade-up" data-aos-duration="1000">Significant cost and time reduction</li>
              <li data-aos="fade-up" data-aos-duration="1000">Maximum efficiency with lesser time-to-market of a product</li>
              <li data-aos="fade-up" data-aos-duration="1000">Intelligent, advanced, rapid, and customer-centric development activities</li>
            </ul>
            </h4>
        </div>
       
   </div>
      
      <div className="row">
        <div className="col-md-12 my-auto">
            <div className="row">
  
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-1"><h3>DevOps Consulting</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-2"><h3>DevOps Implementation</h3></div>  
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-3"><h3>DevOps Management</h3></div>
  </div>
  <div className="row">
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-4"><h3>Automation & Integration</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-5"><h3>Infrastructure Automation Services </h3></div> 
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-6"><h3>CI/CD Pipeline</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-7"><h3>Kubernetes and Serverless Solutions</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-8"><h3>DevOps Strategy for Hybrid and Multi-Cloud</h3>
    </div>
</div>
  <br/>
  

            </div>
            
        </div>
        <div id="ai-service-scroll" className="row py-3">
     <div id="service-action" className="col-md-6 text-center">
   <Link onClick={()=>customeEvent({
        'event_category': 'Services',
        'event_label': 'Devops'
      })}  className="btn-grad" to='/contact'>Let's Discuss</Link>
   </div>
   </div>
    </div>
        


</section>     
     
<section id="service_wrap">
<div  className="container py-5">
    <div className="row">
        <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-6">
            <h2>Artificial Inteligence Solutions</h2>
            <br/>
            <Img fluid={data.fluid4.childImageSharp.fluid} />
            
        </div>

        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 my-auto">
        
        
        
            <h4>
            We enable your business to deliver great customer experiences and become ‘smarter’ by applying AI to your products, business operations, new domains, developing tools, and more.    
           
            </h4>
        </div>
       
   </div>
      
      <div className="row">
        <div className="col-md-12 my-auto">
            <div className="row">
  
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-1"><h3>Machine Learning</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-2"><h3>Digital Virtual Agents</h3></div>  
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-3"><h3> (NLP) Natural Language Processing/ Generation</h3></div>
  </div>
  <div className="row">
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-4"><h3>Computer Vision</h3>
  <ul className="ai-pa-ul py-2">
    <li>Advanced AI components for diverse use-cases</li>
    <li>Improving efficiency with real time data from images and videos</li>
    <li>Making processes automated and flawless at reduced costs</li>
  </ul>
  </div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-5"><h3>Image Processing</h3>
  <ul className="ai-pa-ul py-2">
    <li>Image classification</li>
    <li>Object recognition</li>
    <li>Object tracking</li>
    <li>Image generation</li>
    <li>Image retrieval</li>
  </ul>
  </div> 
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-6"><h3>Predictive Analysis</h3>
  <ul className="ai-pa-ul py-2">
    <li>Smart forecasting</li>
    <li>Fraud Detection</li>
    <li>Empower your business</li>
  </ul>
  </div>
</div>

  <br/>
   
  
  

            </div>
            
        </div>
        <div id="blockchain-service-scroll" className="row py-3">
     <div id="service-action" className="col-md-6 text-center">
   <Link onClick={()=>customeEvent({
        'event_category': 'Services',
        'event_label': 'AI'
      })}  className="btn-grad" to='/contact'>Let's Discuss</Link>
   </div>
   </div>
    </div>
        


</section>     
       
<section id="service_wrap">
<div  className="container py-5">
    <div className="row">
        <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-6">
            <h2>Blockchain Solutions</h2>
            <br/>
            <Img fluid={data.fluid5.childImageSharp.fluid} />
            
        </div>

        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 my-auto">
        
        
        
            <h4>
            We offer blockchain services which serve you with a wide range of decentralized solutions that adds scalability, transparency, and security to your ecosystem.

           
            </h4>
        </div>
       
   </div>
      
      <div className="row">
        <div className="col-md-12 my-auto">
            <div className="row">
  
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-1"><h3>Blockchain Development</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-2"><h3>MVP Development</h3></div>  
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-3"><h3>Smart Contracts Development</h3></div>
  </div>
  <div className="row">
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-4"><h3>End To End DApp Solutions</h3>
  
  </div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-4"><h3>Crypto Exchange Development</h3></div> 
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-5"><h3>Custom Blockchain Solutions</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-6"><h3>Crypto Wallet Development</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-7"><h3>Hyperledger Based Solutions</h3></div>  
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-8"><h3>Private Blockchain</h3></div>
</div>

  <br/>
 
  
  
   
  
  

            </div>
            
        </div>
        <div id="iot-service-scroll" className="row py-3">
     <div id="service-action" className="col-md-6 text-center">
   <Link onClick={()=>customeEvent({
        'event_category': 'Services',
        'event_label': 'Blockchain'
      })} className="btn-grad" to='/contact'>Let's Discuss</Link>
   </div>
   </div>
    </div>
        


</section>  

<section id="service_wrap">
<div  className="container py-5">
    <div className="row">
        <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-6">
            <h2>IoT Solutions</h2>
            <br/>
            <Img fluid={data.fluid6.childImageSharp.fluid} />
            
        </div>

        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 my-auto">
        
        
        
            <h4>
            We offer blockchain services which serve you with a wide range of decentralized solutions that adds scalability, transparency, and security to your ecosystem.

           
            </h4>
        </div>
       
   </div>
      
      <div className="row">
        <div className="col-md-12 my-auto">
            <div className="row">
  
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-1"><h3>IoT consultancy</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-2"><h3>IoT app development</h3></div>  
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-3"><h3>IoT-Wearable connectivity development</h3></div>
  </div>
  <div className="row">
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-4"><h3>IoT testing and maintenance</h3></div>

</div>

  <br/>

  
  
   
  
  

            </div>
            
        </div>
        <div id="bigdata-service-scroll" className="row py-3">
     <div id="service-action" className="col-md-6 text-center">
   <Link onClick={()=>customeEvent({
        'event_category': 'Services',
        'event_label': 'Iot'
      })}  className="btn-grad" to='/contact'>Let's Discuss</Link>
   </div>
   </div>
    </div>
        


</section>        
    
<section id="service_wrap">
<div  className="container py-5">
    <div className="row">
        <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-6">
            <h2>Big Data Solutions</h2>
            <br/>
            <Img fluid={data.fluid7.childImageSharp.fluid} />
            
        </div>

        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 my-auto">
        
        
        
            <h4>
            We Help You To Transform Data Into Powerful Insights That Drive Better Business Decisions.

           
            </h4>
        </div>
       
   </div>
      
      <div className="row">
        <div className="col-md-12 my-auto">
            <div className="row">
  
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-1"><h3>Big Data Strategy Consulting</h3>
  <ul className="bigdata-ul">
    <li>Big Data Strategy Definition</li>
    <li>Business Use Case Identification and Definition</li>
    <li>Data Assessment</li>
    <li>Proof of Concept</li>
    <li>Technology Identification</li>
    <li>Architecture Recommendation</li>
  </ul>
  </div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-2"><h3>Big Data Infrastructure Set-Up & Support</h3>
  <ul className="bigdata-ul">
    <li>Installation and Integration of Hadoop Clusters</li>
    <li>Configuring Hadoop Clusters for optimum performance</li>
    <li>Monitoring of Hadoop Clusters</li>
  </ul>
  </div>  
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-3"><h3>Big Data Maintenance & Support</h3>
  <ul className="bigdata-ul">
    <li>Custom Application Development</li>
    <li>Big Data Application Integration Services</li>
    <li>Integration with existing Enterprise Data Warehouse and Data Sources</li>
    <li>Migration Services</li>
  </ul>
  </div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-4"><h3>Big Data Analytics & Insights Services</h3>
  <ul className="bigdata-ul">
    <li>Inferential Statistical Analysis</li>
    <li>Data Mining</li>
    <li>Data Modelling</li>
    <li>Predictive and Prescriptive Analytics</li>
    <li>Analytics Optimization</li>
    <li>Data Visualization Solutions</li>
  </ul>
  </div>
  </div>
  

  <br/>
  
  
  
   
  
  

            </div>
            
        </div>
        <div id="startup-service-scroll" className="row py-3">
     <div id="service-action" className="col-md-6 text-center">
   <Link onClick={()=>customeEvent({
        'event_category': 'Services',
        'event_label': 'Bigdata'
      })} className="btn-grad" to='/contact'>Let's Discuss</Link>
   </div>
   </div>
    </div>
        


</section>        


<section id="service_wrap">
<div  className="container py-5">
    <div className="row">
        <div data-aos="zoom-in" data-aos-duration="1000" className="col-md-4">
            <h2>Startup Solutions</h2>
            <br/>
            <Img fluid={data.fluid9.childImageSharp.fluid} />
            
        </div>

        <div data-aos="fade-up" data-aos-duration="1000" className="col-md-6 my-auto">
        
        
        
            <h4>
            We provide range of solutions for your emerging startup.

           
            </h4>
        </div>
       
   </div>
      
      <div className="row">
        <div className="col-md-12 my-auto">
            <div className="row">
  
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-1"><h3>Ideation</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-2"><h3>Concepting</h3></div>  
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-3"><h3>Minimum Viable Product (MVP)</h3></div>
  </div>
  <div className="row">
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-4"><h3>Validating & Market Fit</h3></div>
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-4"><h3>Scaling & Establishing</h3></div> 
  <div data-aos="zoom-in" data-aos-duration="1000" className="sbtn btn-5"><h3>Support & Improvements</h3></div>

</div>

  
  
   
  
  

            </div>
            
        </div>
        <div id="startup-service-scroll" className="row py-3">
     <div id="service-action" className="col-md-6 text-center">
   <Link onClick={()=>customeEvent({
        'event_category': 'Services',
        'event_label': 'Startup'
      })} className="btn-grad" to='/contact'>Let's Discuss</Link>
   </div>
   </div>
    </div>
        


</section> 
        </div>
    )
}

export default Services
